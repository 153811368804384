<template>
  <!-- TODO: The background (with images on the sides) and the logo will be shared across the registration pages. -->
  <div class="auth-layout">
    <img class="login-background" :src="require('../../assets/img/img_background_login.png')" />
    <div v-if="buttonActive" class="back-button-wrapper">
      <button @click="goBack">
        <img src="../../assets/icons/btn_back.svg" alt="" />
      </button>
    </div>

    <div class="registration-wrapper">
      <!-- Logo -->
      <img :src="platformLogo" class="auth-logo-big" alt="App logo"/>

      <router-view/>
      <Toast />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import Toast from '@/components/elements/Toast'

export default {
  name: 'Layout',
  components: {
    Toast
  },
  setup() {
    // Imported for testing purposes.
    const router = useRouter()
    const route = useRoute()
    const sidebarLogos = {
      teacherPortal: require('../../assets/icons/icn_logo_full.svg'),
      helpdesk: require('../../assets/icons/icn_helpdesk.svg'),
      cms: require('../../assets/icons/icn_logo_cms.svg'),
      professional: require('../../assets/icons/icn_logo_full.svg')
    }

    const platformLogo = computed(() => {
      switch (route.params.userType) {
        case 'teacherPortal':
          return sidebarLogos.teacherPortal
        case 'cms':
          return sidebarLogos.cms
        case 'helpdesk':
          return sidebarLogos.helpdesk
        case 'professional':
          return sidebarLogos.professional
        default:
          return sidebarLogos.teacherPortal
      }
    })

    const regexSubpageOfRegister = /register\/.+/
    const buttonActive = computed(() => regexSubpageOfRegister.test(route.fullPath) && route.name !== ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT)

    function goBack() {
      router.go(-1)
    }

    return {
      buttonActive,
      platformLogo,

      goBack
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

$auth-background: #F3F6FB;

.back-button-wrapper {
  position: absolute;
  width: rem(36);
  height: rem(36);

  margin: rem(67) 0 0 rem(67);
  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  button:hover {
    filter: brightness(0.9);
  }

  button:active {
    filter: brightness(0.8);
  }
}

.page-form-wrapper {
  margin-top: rem(23);
}

.auth-layout {
  //background-color: $auth-background;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.registration-wrapper {
  margin: 0 auto;
  padding-bottom: rem(104);
  padding-top: rem(104);
  text-align: center;
  max-width: rem(548);
}

.auth-title {
  font-size: rem(34);
  letter-spacing: 0;
  line-height: rem(41);
}

.auth-subtitle {
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);
  margin-bottom: rem(25);
}

.auth-subtitle-gray {
  text-transform: uppercase;
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(17);
  opacity: 0.6;
  margin-bottom: rem(10);
}

.auth-logo-big {
  height: auto;
  margin-bottom: rem(40);
  width: rem(260);
}

.auth-logo-sm {
  height: auto;
  width: rem(180);
}

.auth-form-element {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: rem(28);
  }
}

.auth-check-element {
  position: relative;
  //margin-bottom: rem(16);

  //&:not(:last-of-type) {
  //  margin-bottom: rem(20);
  //}
}

.auth-button {
  margin-top: rem(56);
}

.auth-prompt {
  margin-top: rem(86);
}

.auth-error {
  margin-bottom: rem(42);
  text-align: center;
}

.login-background{
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  min-width: rem(1500);
  height: 100%;
  z-index: -2;
}
</style>
